import React, { useEffect, useMemo } from "react";
import { head, input, remarkStyle, signature } from "../../helper";
import "./mmcqCard.css";
import DefaultImage from "../../../../../images/Navbar/title.png";
import { htmlDecode } from "../../../../../utils/parse";
import { QuestionImg } from "../../../../LearningResources/Assement/Image";
import TestOption from "../../Option";

const parseData = (id) => JSON.parse(localStorage.getItem(id));
const isChecked = (id, optionId) => {
  return localStorage.getItem(id) && parseData(id).includes(optionId);
};

export default function MmcqCard({
  color = "",
  data,
  number,
  correctAnswer,
  setCorrectAnswer,
  onlineTestId,
}) {
  const question = data;

  const id = useMemo(() => {
    return onlineTestId + "," + question._id;
  }, [onlineTestId, question]);

  const handleClick = (e) => {
    if (localStorage.getItem(id)) {
      let parsedData = parseData(id);
      if (parsedData.includes(e.target.value)) {
        parsedData = parsedData.filter((answer) => answer !== e.target.value);
        setCorrectAnswer(parsedData);
      } else {
        parsedData.push(e.target.value);
        setCorrectAnswer(parsedData);
      }
      localStorage.setItem(id, JSON.stringify(parsedData));
    } else {
      let temp = new Array(e.target.value);
      setCorrectAnswer(temp);
      localStorage.setItem(id, JSON.stringify(temp));
    }
  };

  //Uncheck all the options when a new question is rendered
  useEffect(() => {
    setCorrectAnswer([]);
    if (localStorage.getItem(id)) return;

    const radios = document.querySelectorAll(".mmcq_radio");
    radios.forEach((radio) => {
      radio.checked = false;
    });
  }, [number]);

  return (
    <>
      <div className="container question_card my-4">
        <div className="superhead">
          <div className="remark" style={remarkStyle(color)}>
            Select Multiple Options
          </div>
          <div className="mmcq__head" style={head(color)}>
            <div className="mmcqCard">
              <div className="mmcqCard__left">
                <h6
                  style={{ fontWeight: "bolder", marginTop: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(`Q${number}. ${question?.question}`),
                  }}
                ></h6>
              </div>
            </div>
            <div className="main_div">
              {question?.images?.questionImg ? (
                <QuestionImg image={question?.images?.questionImg} />
              ) : null}
              <div
                className="mmcq"
                style={
                  question?.images?.op1 ? { width: "70%" } : { width: "80%" }
                }
              >
                <div className="up top-left">
                  <TestOption
                    image={question.images?.op1}
                    option={question.options[0]}
                    isChecked={isChecked(id, question?.options[0]?.optionId)}
                    select={handleClick}
                  />
                </div>
                <div className="up top-right ml-6">
                  <TestOption
                    image={question.images?.op2}
                    option={question.options[1]}
                    isChecked={isChecked(id, question?.options[1]?.optionId)}
                    select={handleClick}
                  />
                </div>
                <div className="up bottom-left">
                  <TestOption
                    image={question.images?.op3}
                    option={question.options[2]}
                    isChecked={isChecked(id, question?.options[2]?.optionId)}
                    select={handleClick}
                  />
                </div>
                <div className="up bottom-right ml-6">
                  <TestOption
                    image={question.images?.op4}
                    option={question.options[3]}
                    isChecked={isChecked(id, question?.options[3]?.optionId)}
                    select={handleClick}
                  />
                </div>
              </div>
            </div>
            <div className="last">
              {question.onModel === "admin" ? (
                <img
                  src={DefaultImage}
                  className="signarture"
                  style={{ ...signature(color), width: "50px !important" }}
                />
              ) : (
                <div className="signarture" style={signature(color)}>
                  {question.creatorId ? question.creatorId.name : ""}
                  <br />
                  {question.schoolId.name}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
