import React,{useContext,useState,useEffect} from 'react'
import '../../LearningResources/styles/Assesment.css'
import './styles/TestList.css'
import {GlobalContext} from '../../../context/GlobalState'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import TestTable from './TestTable/TestTable'
import SearchBar from './SearchBar/SearchBar'
import TestFilter from './TestFilter/TestFilter'
import {useAlert} from 'react-alert'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive'

const getCurrentDate = ()=>{
    const date = new Date()
    const months = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    
    return `${date.getDate()} ${months[date.getMonth()]},${date.getFullYear()}`
  }

const convertFilterToQuery = (filterData) => {
    let query = "?"
    for(let key in filterData) {
        if(filterData[key]){
            query+=`${key}=${filterData[key]}&`
        }
    }
    return query.slice(0,-1)
} 

const convertArrayToString = (array) => {

    if(!array){
      return ""
    }
    array = array && array.map(item => item.name)
    return array.join(', ')
}


function TestList() {

    //Creating instances
    const params = useParams()
    const {userDetails} = useContext(GlobalContext);
    const alert = useAlert();
    const isSmall = useMediaQuery({query:"(max-width: 472px)"});

    //States
    const [tableData,setTableData] = useState([])

    const [subjects,setSubjects] = useState([])
    const [chapters,setChapters] = useState([])
    const [topics,setTopics] = useState([])
    const [testType,setTestType] = useState(["CT","UT","FT","HY"])
    const [filterData,setFilterData] = useState({
        subjectId:'',
        chapter:'',
        chapterId:'',
        topics:'',
        topicId:params.topicId,
        'from[gte]':null,
        testType:'',
        export: 'true'
    })
    const [searchValue,setSearchValue] = useState("")
    const [loading,setLoading] = useState(false)

    //function to fetch the subjects
    const getSubjects = async()=>{
        const response = await axios(`${process.env.REACT_APP_ORIGIN}/api/v1/grades/${userDetails.grade}/sections/${userDetails.section}/subjects`,{
            headers:{
                token:window.localStorage.getItem('authToken')
            }
        })
        console.log("Response of fetching subjects ====> ",response)
        if(response.data.status === "success"){
            setSubjects(response.data.data.subjects)
        }else{
            alert.error(response.message)
        }
    }

    //function to fetch the chapters
    const getChapters = async(subjectId)=>{
        const response = await axios(`${process.env.REACT_APP_ORIGIN}/api/v1/subjects/${subjectId}`,{
            headers:{
                token:window.localStorage.getItem('authToken')
            }
        })
        console.log("Response of fetching chapters ====> ",response)
        if(response.data.status === "success"){
            setChapters(response.data.data.subject?.chapters || [])
        }else{
            alert.error(response.message)
        }
    }

    //function to fetch the topics
    const getTopics = async()=>{
        const response = await axios(`${process.env.REACT_APP_ORIGIN}/api/v1/chapters/${filterData.chapterId}`,{
            headers:{
                token:window.localStorage.getItem('authToken')
            }
        })
        console.log("Response of fetching topics ====> ",response)
        if(response.data.status === "success"){
            setTopics(response.data.data.chapter?.topics || [])
        }else{
            alert.error(response.message)
        }
    }
    
    //fetch the assigned tests
    const getAssignedTests = async()=>{

        try{
            setLoading(true)
            const response = await axios(`${process.env.REACT_APP_ORIGIN}/api/v1/grades/${userDetails.grade}/sections/${userDetails.section}/assigntests${convertFilterToQuery(filterData)}`,{
                headers:{
                    token:window.localStorage.getItem('authToken')
                }
            })
            console.log("Response of fetching assigned tests ====> ",response)
            if(response.data.status === "success"){
                setTableData(response.data.data.assignTests)
            }else{
                alert.error(response.message)
            }
        }catch(err){
            console.log(err)
            alert.error(err.message)
        }
        setLoading(false)
    }

    //function Search through the data when search value is changed
    const search = () =>{
        let searchData = tableData.filter(test=>convertArrayToString(test.questionSet.chapters).toLowerCase().includes(searchValue.toLowerCase()) || convertArrayToString(test.questionSet.topics).toLowerCase().includes(searchValue.toLowerCase()))
        setTableData(searchData)
    }

    //Fetch the subjects when teh page loads 
    useEffect(() => {
        getAssignedTests()
        getSubjects()
    }, [])
    
    useEffect(() => {
        getAssignedTests()
    },[filterData])

    //Fetch the chapters when the subjectId changes
    useEffect(() => {
        console.log("Filter data ===>",filterData)
        getChapters(filterData.subjectId)
    },[filterData.subjectId])

    //Fetch the topics when the chapter id changes 
    useEffect(() => {
        getTopics()
    },[filterData.chapterId])

    //Call teh search function whenever the user types in search box
    useEffect(() =>{

        //when the search box is empty
        if(!searchValue){
            getAssignedTests()
        }else{
            search()
        }
    },[searchValue])




    return (
        <section className='learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2'>
            <div className='container my-3 upperbox test_list_upper_box'>
                {!isSmall && <div className='box mb-5 col-md-9 row' style={{ cursor: 'pointer',alignItems: 'center',color:"white",backgroundColor: '#C08497',margin:'auto'}}>
                    <h1 className='fw-bold col-sm-9 white_color'>{userDetails.grade + "-" + userDetails.section}</h1>
                    <h5 className="col-sm-3" style={{marginLeft:"auto",fontSize:'90%',fontWeight:'bolder',marginBottom:"auto",textAlign:"right"}}>
                        <CalendarTodayIcon style={{width:"17px"}}/> {getCurrentDate()}
                    </h5>
                    <h3>Online Assessment</h3>
                </div>} 
                <TestFilter 
                    filterData = {filterData}
                    setFilterData = {setFilterData}
                    subjects = {subjects}
                    chapters = {chapters}
                    topics = {topics}
                    testType = {testType}
                />
                <div className="table_container">
                    <div className="search_container">
                        <SearchBar
                            search = {searchValue}
                            setSearch = {setSearchValue}
                        />
                    </div>
                    <TestTable rows = {tableData} loading={loading} ></TestTable>
                </div>
            </div>
        </section>        
    )
}

export default TestList
