import React,{useEffect} from 'react';
import './oneWord.css';
// import EditCard from "../../EditCard/EditCard";
// import data from "../../../sample.json"
import { head, input, remarkStyle, signature } from '../../helper';
import DefaultImage from '../../../../../images/Navbar/title.png'
import { htmlDecode } from '../../../../../utils/parse';
import { QuestionImg } from '../../../../LearningResources/Assement/Image';

export default function OneWord({ color = '',data,number,correctAnswer,setCorrectAnswer,onlineTestId }) {

  const question = data
  const id = onlineTestId + "," + question._id
  
  //Empty the input when a new question is rendered
  useEffect(()=>{
    
    setCorrectAnswer(localStorage.getItem(id))
    if(localStorage.getItem(id)) return

    const inputs = document.querySelector("#one_word_input")
    inputs.value = ""
  },[number])
  
  const handleChange = (e)=>{
    localStorage.setItem(id, e.target.value)
    setCorrectAnswer(e.target.value)
  }

  const parseString = (str) => {
    return new DOMParser()
      .parseFromString(str, "text/html")
      .body.textContent.trimStart();
  }

  return (
    <>
      {/* {edit && <EditCard edit={edit} closeEdit={closeEdit} quesType="oneword" />} */}
      <div className='container question_card my-4'>
        <div className='superhead'>
          <div className='remark' style={remarkStyle(color)}>
            Answer in one word
          </div>
          <div className='one__head' style={head(color)}>
            <div className='oneCard'>
              <div className='oneCard__left'  dangerouslySetInnerHTML={{__html:htmlDecode(`Q${number}. ${question?.question}`)}}>
              </div>
              {/* <div className='oneCard__right'>
                <img src={editImg} onClick={openEdit} />
                <img src={deleteImg} />
              </div> */}
            </div>
            <div className='oneWord'>
              {question?.images?.questionImg ? <QuestionImg image={question?.images?.questionImg} /> : null}<input placeholder='Write your answer here' style={input(color)} value={localStorage.getItem(id)} id="one_word_input" onChange={handleChange} />
            </div>

            <div className='last'>
                {question.onModel==="admin"?<img src={DefaultImage} className='signarture' style={{...signature(color),width:'50px'}} />:<div className='signarture' style={signature(color)}>{question.creatorId?question.creatorId.name:""}<br />{question.schoolId.name}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
