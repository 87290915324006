import { useState } from "react";
import { Zoom } from "../../MyAssessment/Zoom";
export function QuestionImg(props) {
  const [zoom, setZoom] = useState(false);
  return (
    <div className="question_image" style={{ position: "relative" }}>
      {props.image ? (
        <>
          <img
            className="q-img"
            onClick={() => setZoom(true)}
            src={props.image}
          />
          <Zoom image={props.image} zoom={zoom} close={() => setZoom(false)} />
        </>
      ) : null}
    </div>
  );
}

export function OptionImg(props) {
  const [zoom, setZoom] = useState(false);
  return (
    <div
      className="imgBlock"
      onClick={() => {
        setZoom(!zoom)
      }}
      style={
        props.image
          ? {
              position: "relative",
              background: `url(${props.image}) no-repeat center center`,
              backgroundSize: "cover",
              cursor:"zoom-in"
            }
          : { display: "none" }
      }
    >
      {props.image ? (
        <Zoom image={props.image} zoom={zoom} close={() => setZoom(false)} />
      ) : (
        <></>
      )}
    </div>
  );
}
