import ZoomInIcon from "../../images/zoom-in.png";

export function Zoom(props) {
  return (
    <>
      <div
        onClick={props.close}
        style={{
          background: "#00000040",
          position: "fixed",
          top: 0,
          left: 0,
          display: props.zoom ? "flex" : "none",
          width: "100%",
          height: "100%",
          cursor: "zoom-out",
          zIndex: 100,
        }}
      >
        <img
          style={{
            position: "relative",
            zIndex: 200,
            width: "80%",
            padding: "2.5%",
            backgroundColor: "white",
            maxWidth: "600px",
            margin: "auto",
            aspectRatio: "auto",
          }}
          src={props.image}
          onClick={props.close}
        />
      </div>
      <img
        src={ZoomInIcon}
        alt="zoom"
        style={{
          width: "20px",
          position: "absolute",
          right: 0,
          top:"15%",
          zIndex: 50,
          display:props.hideIcon?"none":"block"
        }}
      />
    </>
  );
}
