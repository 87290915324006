import { OptionImg } from "./Image";

export default function Option(props) {
  return (
    <label className="opt1-container" style={props.styles}>
      <span
        dangerouslySetInnerHTML={{
          __html: props.option?.value || "-",
        }}
      ></span>
      <input
        type={props.type || "checkbox"}
        value={props.option.optionId}
        className="mmcq_radio"
        onClick={props.select}
        name="radio"
      />

      <span className="checkmark" style={props.checkmarkStyles}></span>
      {/**Option image */}
      <OptionImg image={props.image} />
    </label>
  );
}
