import React, { useEffect } from "react";
import "./fillBlankCard.css";
import { useState } from "react";
import { remarkStyle, head, signature, input } from "../../helper";
import DefaultImage from "../../../../../images/Navbar/title.png";
import { htmlDecode } from "../../../../../utils/parse";
import { QuestionImg } from "../../Image";

export default function FillBlankCard({
  color = "",
  data,
  number,
  correctAnswer,
  setCorrectAnswer,
}) {
  const [edit, setEdit] = useState(false);
  const question = data;
  useEffect(() => {
    setCorrectAnswer([]);
  }, [number]);

  const handleFillUp = (optionId, value) => {
    for (let i in correctAnswer) {
      if (correctAnswer[i].blankId === optionId) {
        correctAnswer[i].answer = value;
        setCorrectAnswer(correctAnswer);
      }
    }
    setCorrectAnswer([...correctAnswer, { blankId: optionId, answer: value }]);
  };

  //Empty the input when a new question is rendered
  useEffect(() => {
    let inputs = document.querySelectorAll(".fill_up_input_box");
    inputs.forEach((input) => {
      input.value = "";
    });
  }, [number]);

  return (
    <>
      {/* {edit && <EditCard edit={edit} closeEdit={closeEdit} quesType='fib' />} */}
      <div
        className="container question_card  my-4"
        style={{ display: edit ? "none" : "block" }}
      >
        <div className="superhead">
          <div className="remark" style={remarkStyle(color)}>
            Fill In The Blanks
          </div>
          <div className="fb__head" style={head(color)}>
            <div className="fbCard">
              <div
                className="fbCard__left"
                dangerouslySetInnerHTML={{
                  __html: htmlDecode(`Q${number}. ${question?.question}`),
                }}
              ></div>
            </div>
            <div className="fb">
              <QuestionImg image={question?.images?.questionImg}/>
              <div className="fb-fields">
                {data.options.map((option, index) => (
                  <input
                    placeholder="Write your answer here"
                    className="fill_up_input_box"
                    style={{ ...input(color), marginBottom: "5px" }}
                    onChange={(e) => {
                      handleFillUp(option.optionId, e.target.value);
                    }}
                  />
                ))}
                
              </div>
            </div>
            <div className="last">
              {question.onModel === "admin" ? (
                <img
                  src={DefaultImage}
                  className="signarture"
                  style={{ ...signature(color), width: "50px" }}
                />
              ) : (
                <div className="signarture" style={signature(color)}>
                  {question.creatorId ? question.creatorId.name : ""}
                  <br />
                  {question.schoolId.name}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
