import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";
import { image } from "../helpers";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { useMediaQuery } from "react-responsive";
const { isDisabled } = require("../LearningResources/util.js");

const fixName = (name) => {
  const charArrays = ["?", "+", "&", "/"];
  charArrays.forEach((c) => {
    name = name.replaceAll(c, "");
  });
  return name;
};

const MyAssessment = () => {
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  

  const isSmall = useMediaQuery({ query: "(max-width:472px)" });
  const [allSubjects, setAllSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const grade = window.localStorage.getItem("grade");
  const section = window.localStorage.getItem("section");
  const getAllSubjects = async () => {
    await fetch(
      `${process.env.REACT_APP_ORIGIN}/api/v1/grades/${grade}/sections/${section}/subjects`,
      {
        headers: {
          token: `${window.localStorage.getItem("authToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        // let subjects = response?.data?.result ? response?.data?.subjects.filter(item=>item.subjectData.lang===window.localStorage.getItem("language")): []
        let subjects = response.data.subjects.filter(
          (item) =>
            item.subjectData.lang === window.localStorage.getItem("language")
        );

        setAllSubjects(subjects || []);
        if (subjects.length) {
          window.localStorage.setItem("sectionId", subjects[0].sectionId);
        }
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    getAllSubjects();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUserDetails({ ...userDetails, selectedTab: "My Assessment" });
    setUserDetails({
      ...userDetails,
      selectedPath: {
        subject: "",
        chapter: "",
        topic: "",
      },
    });
  }, []);

  const handleClick = (subject) => {
    localStorage.setItem("selectedSubject", subject);
  };

  const getCurrentDate = () => {
    const date = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `${date.getDate()} ${months[date.getMonth()]},${date.getFullYear()}`;
  };

  return (
    <section className="learningResources my-md-5 my-sm-1 p-lg-0 p-2 p-sm-2 py-sm-4col-md-9 col-sm-12 mx-auto">
      <div className="container">
        {!isSmall && (
          <div
            className="box mb-5 row"
            style={{ cursor: "pointer", alignItems: "center" }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                width: "fit-content",
              }}
            >
              <h2
                className="fw-bold col-sm-9 white_color"
                style={{ color: "white", fontWeight: "bolder" }}
              >
                {userDetails?.grade
                  ? `${userDetails?.grade}`
                  : grade && section
                  ? `${grade}-${section}`
                  : "Subjects"}
              </h2>
              <h1 style={{ color: "white", fontWeight: "bolder" }}>
                Practice Assessment
              </h1>
            </span>

            <h5
              className="col-sm-3"
              style={{
                marginLeft: "auto",
                fontSize: "90%",
                fontWeight: "bolder",
                marginBottom: "auto",
                textAlign: "right",
              }}
            >
              <CalendarTodayIcon style={{ width: "17px" }} /> {getCurrentDate()}
            </h5>

            <p className=" white_color" style={{ color: "white" }}>
              Subjects
            </p>
          </div>
        )}
        <div className="row my-3 gy-4 col-15">
          {loading
            ? "Loading ..."
            : allSubjects.length
            ? allSubjects.map((subject) => (
                <div
                  className="col-sm-2 col-6"
                  key={subject._id}
                  style={{
                    display:
                      isDisabled(subject?.subjectData?.chapters) ||
                      subject?.subjectData?.disabled
                        ? "none"
                        : "block",
                  }}
                >
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to={`/myassessment/practice/subjects/${
                      subject.subjectId
                    }/${fixName(subject.subject)}/chapters`}
                    onClick={() => handleClick(subject.subject)}
                  >
                    <div className="card card-height card-radius">
                      <div className="card-img">
                        <img
                          // src={image(fixThumbnailUrl(props.thumbnail))}
                          src={subject?.subjectData?.thumbnail || image()}
                          className="card-thumbnail"
                          alt={subject?.subject || "sub"}
                        />
                      </div>
                      {/* <div className="text-center" style={LearningResourcesStyle}> */}
                      <div className="text-center text-dark">
                        <p
                          dangerouslySetInnerHTML={{ __html: subject?.subject }}
                          className="mar-p"
                        ></p>
                        <p
                          style={{
                            width: "fit-content",
                            margin: "auto",
                            marginBottom: "5px",
                            padding: "2px 15px",
                            border: "1px solid black",
                            background: "#5CE0D280",
                            border: "1px solid #2BA99C",
                            borderRadius: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Chapters {subject?.subjectData?.totalChapters}
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))
            : "0 Subjects to display"}
        </div>
      </div>
    </section>
  );
};

export default MyAssessment;
