import { OptionImg } from "../../LearningResources/Assement/Image";

import { htmlDecode } from "../../../utils/parse";

export default function TestOption(props) {
  return (
    <label className="opt1-container" style={props.styles}>
      <div className="opt-top-card">
        <span
          dangerouslySetInnerHTML={{ __html: htmlDecode(props.option.value) }}
        ></span>
        <input
          type={props.type || "checkbox"}
          value={props.option.optionId}
          checked={props.isChecked}
          className="mmcq_radio"
          onClick={props.select}
          name="radio"
        />
        <span className="checkmark" style={props.checkmarkStyles}></span>
      </div>
      {props.image && <OptionImg image={props.image} />}
    </label>
  );
}
